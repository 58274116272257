import React, { useState, useEffect, useRef } from "react";
import { useFetchLanguages } from "hooks/Quotes/useFetchLanguages";
import ResetButton from "assets/resetbutton.jpg";
import { endpoints } from "utils/endpoints";
import http from "utils/http";
import Layout from "components/layout";
import SEO from "components/seo";
import ToolsBanner from "components/commontools/toolsBanner";
import ToolInput from "components/commontools/InsertTool";
import ToolOutput from "components/commontools/ToolOutput";
import useDebounce from "components/QualityTools/useDebounce";
import { Languages } from "components/QualityTools/LanguagesData";
import ToolsBreadcrumb from "components/ToolsBredcrumb";
import { Link, navigate } from "gatsby";
import { useLocation } from "@reach/router";
import DownloadIcon from "assets/downloadtool.jpg";
import CopyIcon from "assets/copytools.jpg";
import { Tooltip } from "react-tooltip";
import CleanButton from "assets/cleanBtn.svg";
import ToolFaq from "components/QualityTools/ToolFaq";
import { ConsistencyToolFaqData } from "components/FAQ/config";
import Loader from "components/loader";

const GlossaryGeneratorresult = () => {
  const [divHeight, setDivHeight] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [showOutput, setShowOutput] = useState(false);
  const [transtalteFromInput, setTranslateFromInput] = useState("");
  const [transtalteToInput, setTranslateToInput] = useState("");
  const [inputText, setInputText] = useState("");
  const [outputText, setOutputText] = useState("");
  const [inputLang, setInputLang] = useState("");
  const [outputLang, setOutputLang] = useState("");
  const refDiv = useRef(null);
  const [analysisResult, setAnalysisResult] = useState(null);
  const [wordCountInput, setWordCountInput] = useState(0);
  const [wordCountOutput, setWordCountOutput] = useState(0);
  const [copySuccess, setCopySuccess] = useState("Copy");

  // console.log(isProduction, "isproduction");
  const { data } = useFetchLanguages();


    if (refDiv.current) {
      setDivHeight(refDiv.current.offsetHeight);
    }


  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const UpdatreshareId = params.get("share-id");

  const displayShareId = UpdatreshareId;
  useEffect(() => {
    if (!displayShareId) return;
    const fatchAnalysis = async () => {

      try {
        const response = await http().get(
          `${endpoints.forms.aiTools}/${displayShareId}`
        );
        setAnalysisResult(response);
        setShowOutput(true);
        const { source_text: sourceText, translation_text: translatedText } =
          response.meta;
        setInputText(sourceText);
        setOutputText(translatedText);
      } catch (err) {
        setError(err.message || "An unexpected error occurred.");
      } finally {
        setLoading(false);
      }
    };
    fatchAnalysis();
  }, [displayShareId]);

  const isButtonDisabled =
    !transtalteFromInput || !transtalteToInput || !inputText || !outputText;


  const resultValue = analysisResult?.result;

  useEffect(() => {
    if (refDiv?.current) {
      setDivHeight(refDiv?.current?.base?.offsetHeight);
    }
  }, [refDiv, analysisResult]);
  const handleSourceClearText = () => {

    navigate(`/tools/translation-consistency-checker`);

    setInputText("");
   
    setInputLang("");
  
    setWordCountInput(0);

  };

  const handleTargetClearText = () => {

    navigate(`/tools/translation-consistency-checker`);
 
    setOutputText("");
  
    setOutputLang("");
 

    setWordCountOutput(0);
   
  };
  const copyText = () => {
    if (refDiv.current) {
      const textToCopy = refDiv.current.innerText;
      navigator.clipboard.writeText(textToCopy);
      setCopySuccess("Copied");
      setTimeout(() => {
        setCopySuccess("Copy");
      }, 1500);
    }
  };
  const downloadCSV = () => {
    if (!analysisResult) return;

    const resultValue = analysisResult.result;
    const rows = [
      ["Overall Consistency Score", resultValue.overall_consistency_score],
      ["Inconsistency Report", resultValue.consistency_report || "N/A"],
    ];

    const csvContent = rows.map((e) => e.join(",")).join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "consistency_report.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  if (loading)
    return (
 
        <div className="grid place-content-center h-[calc(100vh-150px)]">
          <Loader />
        </div>

    );
    console.log(divHeight,"divheight")
  return (
    <Layout>
      <SEO
        title="Translation Consistency Checker"
        description="Maintain consistent terminology in your translations with the consistency checker. Get a detailed report and consistency score to ensure accuracy.​"
        slug="/tools/translation-consistency-checker"
      />
      <div className="bg-[#F5F5F5] ">
        <div className=" w-full  py-7 ">
          <ToolsBreadcrumb />
        </div>
        <div className="max-w-7xl mx-auto px-4 py-12 lg:py-[60px]">
          <ToolsBanner
            text="Translation Consistency Checker"
            title="Automatically Identify and Fix Terminology Inconsistencies"
            description="Paste the source text and its translation, and the tool will check consistency of terminology. The report will highlight any inconsistent terms or phrases, along with an overall consistency score. "
          />
          <div className="lg:flex gap-3 mt-12">
            <div className="flex flex-col gap-3 w-full lg:w-[70%] ">
              <div className="h-full lg:flex items-center  bg-white rounded-lg shadow-lg ">
                <ToolInput
                  lang={inputLang}
                  text={inputText}
                  wordCount={wordCountInput}
                  setText={setInputText}
                  setOutputText={setOutputText}
                  setInputLang={setInputLang}
                  setOutputLang={setOutputLang}
                  setTranslateToInput={setTranslateToInput}
                  setTranslateFromInput={setTranslateFromInput}
                  setAnalysisResult={setAnalysisResult}
                  setShowOutput={setShowOutput}
                  setWordCount={setWordCountInput}
                  iscleanBtn={true}
                  refDiv={refDiv}
                  result={analysisResult}
                  divHeight={divHeight}
                  isRefreshBtn={true}
                  isResult={true}
                >
                  {" "}
                  <div className="pb-3 border-b border-[#B0B0B0] flex items-center justify-between ">
                        <p className=" text-[#6E6E6E] font-opensans font-bold">
                          Source text
                        </p> 
                        {inputText && (
                        <img
                          src={CleanButton}
                          alt="clean-button"
                          onClick={handleSourceClearText}
                          className="cursor-pointer  absolute right-[14px]"
                        />
                      )}
                      </div>
                </ToolInput>

                <div className="hidden lg:block h-full border border-[#DEDEDE] "></div>

                <ToolInput
                  lang={outputLang}
                  text={outputText}
                  wordCount={wordCountOutput}
                  setText={setOutputText}
                  setWordCount={setWordCountOutput}
                  refDiv={refDiv}
                  result={analysisResult}
                  divHeight={divHeight}
                  placeholder="Enter your text here"
                  isRefreshBtn={true}
                  isTranslation ={true}
                  isResult={true}
                >
                  <div className="pb-3 border-b border-[#B0B0B0] flex items-center justify-between ">
                        <p className=" text-[#6E6E6E] font-opensans font-bold">
                        Translated text
                        </p> 
                        {outputText && (
                        <img
                          src={CleanButton}
                          alt="clean-button"
                          onClick={handleTargetClearText}
                          className="cursor-pointer  absolute right-[14px]"
                        />
                      )}
                      </div>
                </ToolInput>
              </div>
            </div>

            <ToolOutput
           
              result={analysisResult}
              isButtonDisabled={isButtonDisabled}
              ResetButton={ResetButton}
              loading={loading}
         
              showOutput={showOutput}
              error={error}
              refDiv={refDiv}
              isRefreshBtn={true}
              buttontext="Check Consistency"
              text="Your results will appear here. <br/> Please enter your texts and click “Check Consistency” to begin."
            >
              {showOutput && analysisResult && (
                <div className="w-full pb-16 overflow-y-auto" >
                  <div className="flex justify-between items-center border-b border-[#6E6E6E]">
                    <h2 className="text-base font-opensans text-[#6E6E6E] font-bold py-3 ">
                      Inconsistency
                    </h2>
                    {!analysisResult.result.consistency_result == "100%" && (
                      <div className="flex gap-4 ">
                        <button
                          data-tooltip-id="copy-tooltip"
                          data-tooltip-content={copySuccess}
                          onClick={copyText}
                          className=""
                        >
                          <img src={CopyIcon} alt="/copyicons" />
                        </button>
                        <button
                          data-tooltip-id="download-tooltip"
                          data-tooltip-content="Download"
                          onClick={downloadCSV}
                          className=""
                        >
                          <img src={DownloadIcon} alt="/downloadicon" />
                        </button>
                      </div>
                    )}
                    <Tooltip
                      id="copy-tooltip"
                      place="top"
                      multiline={true}
                      className="z-50"
                    />
                    <Tooltip
                      id="download-tooltip"
                      place="top"
                      multiline={true}
                      className="z-50"
                    />
                  </div>
                  <div   style={{ maxHeight: `calc(${divHeight}px - 100px)` }} className={`my-4 flex flex-col gap-3   overflow-y-auto`}>
                    {analysisResult?.result?.overall_consistency_score != "100%" ? (
                      
                        Object.entries(resultValue?.inconsistencies).map(([key, value],index) => (
                       
                           <p key={index}  className="p-3 bg-[#FFDBDB] rounded-lg text-[#424242] text-sm font-normal">
                             {value}  
                           </p>
                     
                         ))
                      // <p className="p-3 bg-[#FFDBDB] rounded-lg text-[#424242] text-sm font-normal">
                      //   {resultValue?.consistency_report ||
                      //     resultValue?.consistency_result}
                      // </p>
                    ) : (
                     
                      <div className="flex flex-col items-center justify-center h-[230px] lg:h-full w-full">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="84"
                          height="80"
                          viewBox="0 0 84 80"
                          fill="none"
                        >
                          <path
                            d="M0 79.4999V70.2058H56V79.4999H0ZM0 42.3234V33.0293H29.4C29.9444 34.7332 30.5667 36.3597 31.2667 37.9087C31.9667 39.4577 32.8222 40.9293 33.8333 42.3234H0ZM0 60.9117V51.6175H43.8666C45.6555 52.7018 47.5611 53.6127 49.5833 54.35C51.6055 55.0873 53.7444 55.6094 56 55.9161V60.9117H0Z"
                            fill="#BDD3FF"
                          />
                          <path
                            d="M57.4 34.1912L73.85 17.6941L70.5834 14.4412L57.4 27.5691L50.75 20.9471L47.4834 24.3162L57.4 34.1912ZM60.6667 46.9706C54.2111 46.9706 48.7092 44.7044 44.1607 40.1719C39.6123 35.6395 37.3365 30.1606 37.3334 23.7353C37.3303 17.31 39.606 11.8311 44.1607 7.29865C48.7154 2.76621 54.2174 0.5 60.6667 0.5C67.116 0.5 72.6196 2.76621 77.1774 7.29865C81.7351 11.8311 84.0094 17.31 84 23.7353C83.9907 30.1606 81.7149 35.6411 77.1727 40.1766C72.6305 44.7121 67.1285 46.9768 60.6667 46.9706Z"
                            fill="#75D778"
                          />
                        </svg>
                        <p className="text-sm text-[#575757] font-opensans font-normal text-center  mt-8">
                          All good! Nothing found.
                        </p>
                      </div>
                    )}
                  </div>

                  <div className="flex gap-2 items-center justify-center p-4 absolute bottom-0">
                    <p className="text-base font-opensans text-[#0A2641] font-semibold">
                      Overall Consistency Score
                    </p>

                    <p className="text-white rounded-3xl px-3 py-1 bg-[#16548D] ">
                  
                      {resultValue.overall_consistency_score}
                    </p>
                  </div>
                </div>
              )}
        
            </ToolOutput>
          </div>
          <p className="w-full text-center text-base mt-12 mb-4 text-[#9C9C9C]">
            Assisted by AI tools. Inaccuracies may occur.
          </p>
       
            <div className="bg-[#F0F5FF] p-6 rounded-lg mt-6 lg:flex justify-between gap-6">
              <div>
                <h2 className="text-2xl font-primary text-[#0A2641] font-bold">
                  Need help translating?
                </h2>
                <p className="text-[#424242] pt-3 leading-6 font-base font-opensans ">
                  If your translation needs improvement, reach out to Tomedes
                  for expert translation, editing, and proofreading services.
                </p>
              </div>
              <Link to="/contactus.php">
                <button className="flex mt-4 md:mt-0 items-center gap-2 bg-[#0A2641] px-6 py-4 text-white rounded-lg">
                  Contact us
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                  >
                    <path
                      d="M1 12.75H23M23 12.75L14.75 4.5M23 12.75L14.75 21"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
              </Link>
            </div>
          
          <p className="mt-8 md:mt-12 text-lg text-center font-opensans leading-[26px]">
            Paste the source text and its translation, and the tool will check
            consistency of terminology. The report will highlight any
            inconsistent terms or phrases, along with an overall consistency
            score.
          </p>
        </div>
      </div>
      <ToolFaq QaToolFaqData={ConsistencyToolFaqData} />
    </Layout>
  );
};

export default GlossaryGeneratorresult;
